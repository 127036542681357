import React, { useState, useEffect } from "react";
import { Route, Router, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import { AuthProvider, useAuth } from "./utils/auth/authContext";
import Header from "./components/MainLayout/Header.jsx";
import CardForm from "./components/MainLayout/CardForm.jsx";
import LeftSidebar from "./components/MainLayout/LeftSidebar.jsx";
import RightSidebar from "./components/MainLayout/RightSidebar.jsx";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegistrationPage.jsx";
import EmailVerificationNotice from "./pages/EmailVerificationNotice.jsx";
import EmailVerificationConfirm from "./pages/EmailVerificationConfirm.jsx";
import UserContext from "./contexts/UserContext";
import ErrorComponent from "./components/Shared/ErrorMessage";
import LoadingBanner from "./components/MainLayout/LoadingBanner.jsx";
import ForgotPasswordForm from "./pages/ForgotPasswordForm.jsx";
import PasswordResetForm from "./pages/PasswordResetForm.jsx";
import axiosInstance from "./utils/axiosConfig.js";
import { debounce } from "lodash";

import "./App.css";

const MainPage = ({ isPatreonConnected }) => {
  const [sidebarText, setSidebarText] = useState("");
  const [sidebarWeight, setSidebarWeight] = useState(5);
  const [counter, setCounter] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [otherValues, setOtherValues] = useState({
    cardName: 5,
    color: 0,
    typeLine: 0,
    keywords: 0,
    tokens: 0,
    favorText: 0,
  });

  const [engineValues, setEngineValues] = useState({
    cfg_scale: 7,
    clip_guidance_preset: "NONE",
    sampler: "DDIM",
    steps: 25,
    stylePreset: "fantasy-art",
  });

  useEffect(() => {
    const user = localStorage.getItem("userId");
    if (user) {
      fetchUserTokens(user).then((tokens) => {
        if (tokens !== null) {
          setCounter(tokens);
        }
      });
    }
  }, []);

  const fetchUserTokens = async (userId) => {
    try {
      const response = await axiosInstance.get(`/api/user/tokens/${userId}`);
      return response.data.tokens;
    } catch (error) {
      console.error("Error fetching user tokens:", error);
      return null;
    }
  };

  const handleClearError = () => {
    setErrorMessage("");
  };

  const updateCounterInBackend = async (userId, newCounterValue) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance.put(
        `/api/user/update-tokens/${userId}`,
        {
          tokens: newCounterValue,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.status === 200 && response.data && response.data.tokens === newCounterValue;
    } catch (error) {
      throw error;
    }
  };

  const decrementCounter = (decrementAmount = 1) => {
    setCounter((prevCounter) => {
      const newCounterValue = Math.max(0, prevCounter - decrementAmount);

      if (newCounterValue !== prevCounter) {
        const user = localStorage.getItem("userId");
        updateCounterInBackend(user, newCounterValue)
          .then((success) => {
            if (!success) {
              console.error("Backend update failed");
            }
          })
          .catch((error) => {
            console.error("Error updating counter in backend:", error);
            setErrorMessage("Failed to update counter in the backend.");
          });
      }

      return newCounterValue;
    });
  };

  return (
    <div className="container">
      {isLoading && <LoadingBanner />}
      <Header isConnected={isPatreonConnected} />
      <ErrorComponent errorMessage={errorMessage} onClearError={handleClearError} />
      <div className="content">
        <LeftSidebar text={sidebarText} weight={sidebarWeight} setText={setSidebarText} setWeight={setSidebarWeight} otherValues={otherValues} setOtherValues={setOtherValues} engineValues={engineValues} setEngineValues={setEngineValues} />
        <CardForm sidebarText={sidebarText} sidebarWeight={sidebarWeight} otherValues={otherValues} engineValues={engineValues} decrementCounter={decrementCounter} counter={counter} isLoading={isLoading} setIsLoading={setIsLoading} setErrorMessage={setErrorMessage} />
        <RightSidebar counter={counter} errorMessage={errorMessage} />
      </div>
    </div>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  );
};

const AppRoutes = () => {
  const { isAuthenticated, isLoading, isPatreonConnected: initialPatreonConnected } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isPatreonConnected, setIsPatreonConnected] = useState(initialPatreonConnected);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate("/"); // Ensure we navigate when isAuthenticated changes
    }
  }, [isAuthenticated, navigate, isLoading]);

  useEffect(() => {
    if (!isPatreonConnected) {
      setIsPatreonConnected(initialPatreonConnected);
    }
  }, [initialPatreonConnected]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const patreonConnected = urlParams.get("patreonConnected");
    const error = urlParams.get("error");

    if (patreonConnected === "true") {
      setIsPatreonConnected(true);
      alert("Patreon account successfully connected!");
      navigate("/"); // Redirect to home or any other page you want after successful connection
    } else if (patreonConnected === "false" && error === "already_linked") {
      alert("This Patreon account is already linked to another user.");
      navigate("/login"); // Redirect to login or any other page you want after error
    }
  }, [location, navigate]);

  if (isLoading) {
    return (
      <div className="loading-banner">
        <span>Loading...</span>
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={isAuthenticated ? <Navigate to="/" replace /> : <LoginPage onLoginSuccess={() => navigate("/")} setPatreonConnection={setIsPatreonConnected} />} />
      <Route path="/" element={isAuthenticated ? <MainPage isPatreonConnected={isPatreonConnected} /> : <Navigate to="/login" replace />} />
      <Route path="/register" element={!isAuthenticated ? <RegisterPage /> : <Navigate to="/" replace />} />
      <Route path="/verify-email-notice" element={<EmailVerificationNotice />} />
      <Route path="/verify-email" element={<EmailVerificationConfirm />} />
      <Route path="/forgot-password" element={<ForgotPasswordForm />} />
      <Route path="/reset-password" element={<PasswordResetForm />} />
    </Routes>
  );
};

export default App;
