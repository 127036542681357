import React, { createContext, useState, useContext, useEffect } from 'react';
import axiosInstance from '../../utils/axiosConfig'; // Import your configured axios instance

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isPatreonConnected, setIsPatreonConnected] = useState(false);

    const refreshAccessToken = async () => {
        const refreshToken = localStorage.getItem("refreshToken");
        if (!refreshToken) {
            console.error("Failed to refresh token: refreshToken is null");
            return;
        }
        try {
            const { data } = await axiosInstance.post("/api/auth/token", {
                refreshToken: refreshToken,
            });
            if (!data.accessToken) {
                console.error("Failed to refresh token: accessToken is null");
                return;
            }
            localStorage.setItem("accessToken", data.accessToken);
            localStorage.setItem("userId", data.user);
            setIsAuthenticated(true);
        } catch (error) {
            console.error("Failed to refresh token", error);
            setIsAuthenticated(false);
            setUser(null);
        }
    };

    const setupRefreshTokenInterval = () => {
        return setInterval(refreshAccessToken, 3600 * 1000); // Refresh every hour
    };

    useEffect(() => {
        let refreshTokenInterval;
        const fetchUserData = async () => {
            setIsLoading(true);
            const accessToken = localStorage.getItem("accessToken");
            if (accessToken) {
                try {
                    const { data } = await axiosInstance.post("/api/auth/validate-access-token", { accessToken });
                    setIsAuthenticated(true);
                    localStorage.setItem("userId", JSON.stringify(data.user.id));
                    setIsPatreonConnected(data.hasPatreonLinked || false);
                    refreshTokenInterval = setupRefreshTokenInterval();
                } catch (error) {
                    setIsAuthenticated(false);
                    setUser(null); // Ensure user is set to null on error
                } finally {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        };

        fetchUserData();

        return () => {
            if (refreshTokenInterval) {
                clearInterval(refreshTokenInterval);
            }
        };
    }, []);

    return (
        <AuthContext.Provider value={{ user, setUser, isAuthenticated, setIsAuthenticated, isPatreonConnected }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
