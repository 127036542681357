import React, { useState, useEffect } from 'react';
import { fetchImageThroughProxy } from '../../utils/axiosConfig.js';
import { getBorderStyle } from '../Shared/Borders.jsx';
const FetchImages = ({className,  imageUrl, colors, mana_cost, color_identity, handleImageLoaded, layout }) => {
  const [proxyImageUrl, setProxyImageUrl] = useState(null);

  useEffect(() => {
    async function getImage() {
      try {
        const proxyUrl = await fetchImageThroughProxy(imageUrl);
        setProxyImageUrl(proxyUrl);
      } catch (error) {
        console.error("Error loading image:", error);
      }
    }

    if (imageUrl) {
      getImage();
    }
  }, [imageUrl]);

  return (
    <div className={className} style={getBorderStyle(colors, mana_cost, color_identity, layout)}>
      {proxyImageUrl && <img src={proxyImageUrl} onLoad={handleImageLoaded} crossOrigin="anonymous" alt="Generated" />}
    </div>
  );
}

export default FetchImages;
