import axios from "axios";

// Use the environment variable or default to localhost for development
const baseURL = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";
const axiosInstance = axios.create({
  baseURL: baseURL,
});

export const fetchImageThroughProxy = async (imageUrl) => {
  try {
    const response = await axiosInstance.get(`/proxy-image`, {
      params: { url: imageUrl },
      responseType: "arraybuffer", // Handle image data
    });

    // Convert the response to a blob and create an object URL
    const blob = new Blob([response.data], { type: response.headers["content-type"] });
    const imageObjectUrl = URL.createObjectURL(blob);

    return imageObjectUrl;
  } catch (error) {
    console.error("Failed to fetch image through proxy:", error);
    throw error;
  }
};


async function refreshTokenAndRetry(failedRequest) {
  try {
    // Call your refresh token endpoint
    const { data } = await axiosInstance.post("/api/auth/token", {
      refreshToken: localStorage.getItem("refreshToken"),
    });

    // Update access token
    localStorage.setItem("accessToken", data.accessToken);
    failedRequest.headers["Authorization"] = "Bearer " + data.accessToken;

    // Retry the original failed request with the new access token
    return axiosInstance(failedRequest);
  } catch (error) {
    console.error("Failed to refresh token", error);

    // Cleanup local storage and redirect to login
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.href = "/login";

    return Promise.reject(error);
  }
}

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.error("API Error:", error);
    if (error) {
      console.error("API Error without response:", error);
      return Promise.reject(error);
    }

    const { status, data } = error.response;
    const originalRequest = error.config;

    // Avoid infinite loops
    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (data.message === "Invalid access token" || data.message === "Token is invalid or expired") {
        // return refreshTokenAndRetry(originalRequest);
      }
    }

    if (status === 403 && data.message === "Refresh token is invalid or expired") {
      // Handle logout procedure
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      window.location.href = "/login"; // Redirect to login
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("accessToken");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
