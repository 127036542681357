import React, { useState } from 'react';
import EmailLoginForm from '../components/Inputs/EmailLoginForm';
import { Link } from 'react-router-dom';
const LoginPage = ({ onLoginSuccess, setPatreonConnection }) => {
  const [loginError, setLoginError] = useState(null);

  const handleSuccessfulLogin = () => {
    onLoginSuccess();
  };

  return (
    <div className="auth-container">
      <div className="card">
        <h1>Arcane-Proxies</h1>
        <EmailLoginForm onSuccessfulLogin={handleSuccessfulLogin} setPatreonConnection={setPatreonConnection} />
        {loginError && <p style={{ color: 'red' }}>{loginError}</p>}
        <p>Don't have an account? <Link to="/register">Register here</Link></p>
      </div>
    </div>
  );
};

export default LoginPage;